<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图区域 -->
    <el-card class="box-card">
      <!-- 搜索区域 -->
      <el-row>
        <el-col :span="8">
          <el-input placeholder="请输入搜索内容" clearable v-model.trim="queryInfo.query" @clear="getOrdersList">
            <el-button slot="append" icon="el-icon-search" @click="queryInfo.query?getOrdersList():''"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!-- 表格区域 -->
      <el-table :data="ordersList" border style="width: 100%" :stripe="true">
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column prop="order_number" label="订单编号" align="center"></el-table-column>
        <el-table-column prop="order_price" label="订单价格" align="center"></el-table-column>
        <el-table-column label="是否付款" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.pay_status === '1'">已付款</el-tag>
            <el-tag type="danger" v-else>未付款</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="is_send" label="是否发货" align="center"></el-table-column>
        <el-table-column label="下单时间" align="center">
          <template slot-scope="scope">
            {{scope.row.create_time*1000 | dateFormat}}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-tooltip content="修改订单地址" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditAddrDialog(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip content="物流进度" placement="top" :enterable="false">
              <el-button type="success" icon="el-icon-location" size="mini" @click="showProcessDialog(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[1, 2, 5, 10, 15, 20]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background>
      </el-pagination>
    </el-card>
    <!-- 修改订单地址对话框 -->
    <el-dialog title="修改订单地址" :visible.sync="editDialogVisible" width="50%" @close="editDialogClosed">
      <el-form :model="editAddrForm" :rules="addrFormRules" ref="editFormRef" label-width="100px">
        <el-form-item label="省市区/县" prop="addr1">
          <!-- 级联选择器区域 -->
          <!-- options：可选项数据源 -->
          <!-- props：数据配置选项 -->
          <el-cascader
          v-model="editAddrForm.addr1"
          :options="cityData"
          :props="cityProps"
          clearable>
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="addr2">
          <el-input v-model.trim="editAddrForm.addr2"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="editDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="editAddr">确 定</el-button>
      </span>
    </el-dialog>
     <!-- 物流进度对话框 -->
    <el-dialog title="物流进度" :visible.sync="processDialogVisible" width="50%">
      <!-- 时间线 -->
      <!-- <el-timeline :reverse="true">
        <el-timeline-item v-for="(item, index) in processList" :key="index" :timestamp="item.timestamp">
          {{item.content}}
        </el-timeline-item>
      </el-timeline> -->
    </el-dialog>
  </div>
</template>
<script>
import cityData from './citydata.js'
export default {
  data () {
    return {
      queryInfo: {
        query: '', // 查询参数
        pagenum: 1, // 页码值
        pagesize: 2 // 每页显示的条目数
      },
      // 订单列表
      ordersList: [],
      // 订单总数
      total: 0,
      // 控制修改地址对话框显示/隐藏
      editDialogVisible: false,
      // 修改订单地址表单数据
      editAddrForm: {
        addr1: [],
        addr2: ''
      },
      // 全国所有城市数据
      cityData,
      cityProps: {
        // 鼠标悬停展开次级选项
        expandTrigger: 'hover'
      },
      // 修改订单地址预校验规则
      addrFormRules: {
        addr1: [
          { required: true, message: '请选择省市区/县', trigger: 'blur' }
        ],
        addr2: [
          { required: true, message: '请填写详细地址', trigger: 'blur' }
        ]
      },
      // 控制物流进度对话框显示/隐藏
      processDialogVisible: false,
      // 模拟物流进度数据
      processList: [
        {
          timestamp: '已下单',
          content: '2021-05-20 10:30'
        },
        {
          timestamp: '快递员已揽件',
          content: '2021-05-20 17:25'
        },
        {
          timestamp: '正在发往广州中转站',
          content: '2021-05-20 16:45'
        }
      ]
    }
  },
  created () {
    this.getOrdersList()
  },
  methods: {
    async getOrdersList () {
      const { data: res } = await this.$http.get('orders', { params: this.queryInfo })
      console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: '获取订单列表失败',
          center: true,
          duration: 1000
        })
      }
      // 获取商品列表成功
      this.ordersList = res.data.goods
      this.total = res.data.total
    },
    // 每页显示的条目数变化时触发的事件处理
    handleSizeChange (newPagesize) {
      this.queryInfo.pagesize = newPagesize
      this.getOrdersList()
    },
    // 页码值变化时触发的事件处理
    handleCurrentChange (newPagenum) {
      this.queryInfo.pagenum = newPagenum
      this.getOrdersList()
    },
    // 打开修改订单地址对话框
    showEditAddrDialog () {
      this.editDialogVisible = true
    },
    // 关闭修改订单地址对话框时触发的事件处理
    editDialogClosed () {
      // 重置表单数据
      this.$refs.editFormRef.resetFields()
    },
    // 点击按钮，提交修改地址
    editAddr () {
      this.$refs.editFormRef.validate((valid) => {
        if (!valid) return false
        // 表单预校验通过
        const addrData = this.editAddrForm.addr1.join('') + this.editAddrForm.addr2
        this.$message({
          type: 'success',
          message: `修改地址成功：${addrData}`
        })
        this.editDialogVisible = false
      })
    },
    // 打开物流进度对话框
    async showProcessDialog () {
      // const { data: res } = await this.$http.get('/kuaidi/1106975712662')
      // console.log(res)
      // if (res.meta.status !== 200) {
      //   return this.$message({
      //     type: 'error',
      //     message: '获取物流进度信息失败',
      //     center: true,
      //     duration: 1000
      //   })
      // }
      // this.processList = res.data
      this.processDialogVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
  .el-cascader{
    width: 100%;
  }
</style>
